@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';

@font-face {
    font-family: 'just-exbold';
    src: url('./assets/fonts/JUST\ Sans\ ExBold.otf');
}

@font-face {
    font-family: 'just-regular';
    src: url('./assets/fonts/JUST\ Sans\ Regular.otf');
}

@font-face {
    font-family: 'rocket';
    src: url('./assets/fonts/ROCKET\ WILDNESS.ttf');
}

@font-face {
    font-family: 'walkway-rounded';
    src: url('./assets/fonts/Walkway\ rounded.ttf');
}

@font-face {
    font-family: 'walkway-semibold';
    src: url('./assets/fonts/Walkway\ SemiBold.ttf');
}

@font-face {
    font-family: 'walkway-ultrabold';
    src: url('./assets/fonts/Walkway\ UltraBold.ttf');
}

html, body{
    overflow: none;
}
body {
    margin: 0;
    position: relative;
    overflow-x: hidden;
}

* {
    margin: 0;
}

*::before,
*::after {
    box-sizing: border-box;
}



.form-control {
    margin: 0 !important;

    &:focus {
        border-color: #ced4da;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }
}

.mat-mdc-menu-panel {
    overflow-x: hidden !important;
    max-height: 380px !important;
}

.mdc-menu-surface::-webkit-scrollbar {
    width: 8px;
}

.mdc-menu-surface::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #ffffff;
}


.mdc-menu-surface::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
}

.mat-mdc-form-field-subscript-wrapper {
    height: 0 !important;
}

.toast-container {
    right: 20px;
    margin: 0 auto;
    bottom: 20px !important;
    top: unset !important;
    font-family: 'just-regular';
    font-size: 13px;
    letter-spacing: 2px;
}

.ngx-toastr {
    margin: 0 auto 10px auto !important;
}

@media (max-width:500px) {

    .toast-container {
        left: 0 !important;
        right: 0 !important;
        margin: 0 auto;
    }

    .cdk-overlay-connected-position-bounding-box {
        bottom: 0 !important;
        left: 0 !important;
        width: 100vw !important;
        height: 100vh !important;
        top: 0 !important;
        right: 0 !important;
        margin: 0 auto;
    }

    .cdk-overlay-pane {
        width: 100vw !important;
        bottom: 0 !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        margin: 0 auto !important;
        position: absolute !important;
        z-index: 1000 !important;
        height: 100vh;
        max-width: 100vw !important;
        max-height: 100vh !important;
    }

    .mat-mdc-menu-panel {
        width: 100% !important;
        max-width: 100% !important;
        max-height: 100vh !important;
    }

    .country-search:focus-visible {
        border: none !important;
        outline: none !important;
    }
}

.title-bloc {
    font-size: 25px;
    padding: 7px;
    font-family: "just-exbold";
    text-transform: uppercase;
    text-align: center;
    background: #3b7c21;
    color: white;
    position: relative;
    z-index: 100;
    box-shadow: 0px 6px 3px 0px rgba(0, 0, 0, 0.2);
    margin-top: 50px;
}

.containt-small {
    display: flex;
    justify-content: end;
    width: 100%;
}

small {
    padding-top: 5px;
    color: red;
    font-size: 11px !important;
}


.title-master {
    font-size: 30px;
    color: #a0d26f;
    font-family: "just-regular";
    margin-bottom: 40px;
    letter-spacing: -2px;
    border-bottom: 1px solid #a0d26f;
    line-height: 30px;
    padding-bottom: 5px;

    &.xs-view {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 0;
    }
}
#panorama-container{
    width: 100%;
    height: calc(100vh - 95px);
}

.just-bold{
    font-family: 'just-exbold';
}

.wizz {
    animation: shake 0.3s;
  }
  
  @keyframes shake {
    0% {
      transform: translate(0);
    }
  
    25% {
      transform: translate(-20px);
    }
  
    50% {
      transform: translate(20px);
    }
  
    75% {
      transform: translate(-20px);
    }
  
    100% {
      transform: translate(0);
    }
}

.containt-data-legals {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 5px;
    margin-top: 25px;

    input {
        width: max-content;
        display: flex;
    }

    .mentions-legals {
        width: 100%;
        display: flex;
        text-align: justify;
        font-size: 12px;
        line-height: 15px;
        color: white;
        font-family: 'just-regular';
    }
}